import React, {useState, useEffect} from 'react';

const path = require('path');
require('dotenv').config({path: path.resolve(__dirname, '../../../.env')});

const { FB_ACCESS_TOKEN } = process.env;


//Props Interfaces: 
interface pageDivProps {
    id : number; 
    post : string; 
}

//Back Content for Facebook in case API goes down!!
const fbBackupString: string[] = [`It's official!
Today, I filed to run for Pasadena ISD School Board position 4.
Not only am I doing this for the future of my two boys, but for my wife, Nikki – a teacher in PISD. Teachers give their time and energy to support our children and it is time the school board supports them in return.
Throughout my campaign, there are opportunities to get to know me and my platform. First and foremost, however, I'm looking to increase transparency and accountability of school board members. As such, always feel like you can reach out to me with any questions or comments. And please join me as I look to fulfill the needs of our community to create "Better Schools for a Better Tomorrow".`,
 `My highest priority as a member of Pasadena Independent School District’s board is to support our school system’s long-term success so that every PISD student can get the very best education available. This work is not possible without our incredible teachers, principals, school bus drivers, staff, and superintendent, nor the extraordinary support of current and former Board members as well as our parents, families, and community.
As a former public school teacher, husband to a current teacher, and father of two young sons, there are three main reasons why I’ve chosen to run for the school board:
-- Teachers and students are best served when those in our community are engaged and heard. Additionally, I believe that school districts should drive their decision-making through how students, teachers, and families are impacted. That’s why throughout the campaign process – and, should I be elected, throughout my tenure on the Board – I‘ll host online events and be accessible to residents and my neighbors throughout Pasadena.
-- Communication is a responsibility, not an afterthought. From budget planning to policy changes to accurate COVID-19 information, open and thoughtful communication is critical as it often leads to greater collaboration and cooperation, as well as encourages community support. After all, school boards exist to provide support and oversight from the community to the school district.
-- In these challenging times and always, teachers deserve more balanced and supportive resources. Additionally, students need integrated education methodologies to become resilient, achieving members of society. This holistic approach not only helps children develop academically and intellectually but prepares students for the future.`
];



//Important infomration and helper functions: 

const PostDivs : React.FC<pageDivProps> = (props) => {
    return (
        <div className="post-container">
            <div id={`fb-post-${props.id}`} className="post fb-post">
                {props.post}
            </div>
        </div>
    )
} 

const prepareContainers = (data : string[]) => {
    let postDivs = data.map((url, i) => {
        return <PostDivs post={url} id={i} />
    });

    return postDivs;
}






const FacebookContent: React.FC = () => {

   const [pagePosts, setPosts] = useState<string[]>([]);
   const getPagePosts = async () => {
        let posts : string[] = []; 
        await window.FB.api('/106105591432150/posts', 'get', {access_token : FB_ACCESS_TOKEN}, function(response : any) {
            const data = response.data; 
            console.log(data)
            if (data !== undefined)
            {
                data.forEach( (post : any, i : number) => {
                    if (i <= 2)
                        posts.push(post.message);
                });
            }
            else {
                posts = fbBackupString;
            }
            setPosts(posts);
        });
    }
    
    useEffect(() => {
        getPagePosts();   
    }, []);

    const pageContainers = prepareContainers(pagePosts);
    
    return (
        <div id="fb-contents" className="tab-content">
            {pageContainers}
            <div className="see-more">Click <a href="https://www.facebook.com/Areef-Bhuyan-for-Pasadena-School-Board-110565990859276" className="soc-med-link" rel="noreferrer" target="_blank">here</a> to see more!</div>
        </div>
    )
}

export default FacebookContent; 

