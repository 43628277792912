import React, {useState} from 'react'; 

//Sub-Component Imports: 
import InstaContent from './InstaContent';
import TwitterContent from './TwitterContent';
import FacebookContent from './FacebookContent';

//Icon Imports: 
import {FaFacebookF, FaInstagram, FaTwitter} from 'react-icons/fa'; 



const SocialMediaUpdates : React.FC = () => {

    //Hook Definitions: 
    const [tabNumber, setTabNumber] = useState(1); 

    //Determining Social Media Tab Content to Show: 
    let currentTab;
    if (tabNumber === 1) 
        currentTab = <InstaContent />;
    else if (tabNumber === 2)
        currentTab = <TwitterContent />;
    else 
        currentTab = <FacebookContent />;


    return (
            <div className="main-content-container">
                <h1 className="page-title">The Latest Updates From My Social Media</h1>
                <div className="social-media-container page-content-container">
                    <div className="tabs-container">
                        <button onClick={() => setTabNumber(1)} id="insta-tab" className="tab social-media-tab"> 
                            <FaInstagram className="tab-icon"/> 
                            <span className="tab-label">Instagram</span>
                        </button>
                        <button onClick={() => setTabNumber(2)} id="twitter-tab" className="tab social-media-tab"> 
                            <FaTwitter />  
                            <span className="tab-label">Twitter</span>
                        </button>
                        <button onClick={() => setTabNumber(3)} id="fb-tab" className="tab social-media-tab">  
                            <FaFacebookF />  
                            <span className="tab-label">Facebook</span>
                        </button>
                    </div>
                    <div className="tabs-content-container"> 
                        {currentTab}
                    </div>
                </div>
            </div>
    ) 
}


export default SocialMediaUpdates; 