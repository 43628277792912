import React from 'react';
import { isMobile } from 'react-device-detect';

const MainCalendar : React.FC = () => {

    //URL Variables: David - When I used the variables, I could not get the calendar to display. If I put the url directly in the iframe, it works. Maybe you can try it out if you have a chance?
    /*const fullCalURL = "https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%234285F4&amp;ctz=America%2FChicago&amp;src=ZWxlY3RiaHV5YW5AZ21haWwuY29t&amp;color=%23039BE5&amp;showPrint=0&amp;showTz=0&amp;title=Areef%20Bhuyan%20for%20Pasadena%20ISD"; 
    const mobileCalURL = "https://calendar.google.com/calendar/embed?height=500&amp;wkst=1&amp;bgcolor=%231da1f2&amp;ctz=America%2FChicago&amp;showTitle=0&amp;showDate=1&amp;showPrint=0&amp;showTabs=1&amp;showCalendars=1&amp;mode=AGENDA&amp;showNav=0&amp;src=ZWxlY3RiaHV5YW5AZ21haWwuY29t&amp;color=%23039BE5";
*/
    //Check to see if mobile sceen is used: 
    const sizeAdaptedCalendar = (() => {                            
        if (isMobile)
            return <iframe className="mobile-cal" src="https://calendar.google.com/calendar/embed?height=500&amp;wkst=1&amp;bgcolor=%231da1f2&amp;ctz=America%2FChicago&amp;showTitle=0&amp;showDate=1&amp;showPrint=0&amp;showTabs=1&amp;showCalendars=1&amp;mode=AGENDA&amp;showNav=0&amp;src=ZWxlY3RiaHV5YW5AZ21haWwuY29t&amp;color=%23039BE5" title="Mobile Calendar" width="425" height="500" frameBorder="0" scrolling="no"></iframe>;
        else 
            return <iframe className="full-cal" src="https://calendar.google.com/calendar/embed?height=600&amp;wkst=1&amp;bgcolor=%234285F4&amp;ctz=America%2FChicago&amp;src=ZWxlY3RiaHV5YW5AZ21haWwuY29t&amp;color=%23039BE5&amp;showPrint=0&amp;showTz=0&amp;title=Areef%20Bhuyan%20for%20Pasadena%20ISD" title="Full Calendar" width="800" height="500" frameBorder="0" scrolling="no"></iframe>
    })();
     
    return (
        <div className="calendar-container">
            <div id="calendar" className="calendar">
                {sizeAdaptedCalendar}
            </div>
        </div>
    )
}

export default MainCalendar;