import React from 'react'; 

import image1 from '../../images/IMG_8435.jpg';
import image2 from '../../images/IMG_2021.jpg';
import image3 from '../../images/IMG_2022.jpg';
import image4 from '../../images/IMG_9342.jpg';
import image5 from '../../images/IMG_6496.jpg';
import image6 from '../../images/IMG_6500.jpg';
import image7 from '../../images/IMG_6718.jpg';
import image8 from '../../images/IMG_7080.jpg';
import image9 from '../../images/IMG_7906.jpg';
import image10 from '../../images/IMG_5621.jpg';
import image11 from '../../images/IMG_2020.jpg';
import image12 from '../../images/IMG_8518.jpg';
import image13 from '../../images/IMG_8714.jpg';



const AboutContents : React.FC = () => {


    return (
        <div id="about-contents" className="tab-content">
            <div className="about-bio-container page-content-container">
                <img src={image1} alt="Failed to load" className="bio-photo" />
                <div className="intro-box bio-box">
                    <p className="intro bio">From a young age, Areef Bhuyan possessed a strong passion for education. This devotion led to a Bachelor of Music in Education at the University of Houston’s Moore’s School of Music and almost a decade as a band director, including two years in Pasadena ISD. Areef also holds an MBA in finance from Louisiana State University - Shreveport.</p>
                    <p>Areef and his wife Nikki (distinguished recipient of Pasadena ISD’s district teacher of the year for 2017-2018) are proud residents of Pasadena, calling the District home since 2012. With two young, school-aged children, Areef has already served in our schools and communities.</p>
                    <p>As a family who will remain at PISD schools for many years to come, Areef is dedicated to helping our teachers give our children the best education possible.</p>
                </div>
                <div className="gallery">
                    <div className="photo-card" id="card1">
                        <a href={image2} className="gal-img-link" target="_blank" rel="noreferrer"><img src={image2} alt="failed to load" className="gallery-img"/></a>
                    </div>
                    <div className="photo-card" id="card2">
                        <a href={image3} className="gal-img-link" target="_blank" rel="noreferrer"><img src={image3} alt="failed to load" className="gallery-img"/></a>
                    </div>
                    <div className="photo-card" id="card3">
                        <a href={image11} className="gal-img-link" target="_blank" rel="noreferrer"><img src={image11} alt="failed to load" className="gallery-img"/></a>
                    </div>
                    <div className="photo-card" id="card4">
                        <a href={image12} className="gal-img-link" target="_blank" rel="noreferrer"><img src={image12} alt="failed to load" className="gallery-img"/></a>
                    </div>
                    <div className="photo-card" id="card5">
                        <a href={image13} className="gal-img-link" target="_blank" rel="noreferrer"><img src={image13} alt="failed to load" className="gallery-img"/></a>
                    </div>
                    <div className="photo-card" id="card6">
                        <a href={image10} className="gal-img-link" target="_blank" rel="noreferrer"><img src={image10} alt="failed to load" className="gallery-img"/></a>
                    </div>
                    <div className="photo-card" id="card7">
                        <a href={image6} className="gal-img-link" target="_blank" rel="noreferrer"><img src={image6} alt="failed to load" className="gallery-img"/></a>
                    </div>
                    <div className="photo-card" id="card8">
                        <a href={image5} className="gal-img-link" target="_blank" rel="noreferrer"><img src={image5} alt="failed to load" className="gallery-img"/></a>
                    </div>
                    <div className="photo-card" id="card9">
                        <a href={image9} className="gal-img-link" target="_blank" rel="noreferrer"><img src={image9} alt="failed to load" className="gallery-img"/></a>
                    </div>
                    <div className="photo-card" id="card10">
                        <a href={image4} className="gal-img-link" target="_blank" rel="noreferrer"><img src={image4} alt="failed to load" className="gallery-img"/></a>
                    </div>
                    <div className="photo-card" id="card11">
                        <a href={image7} className="gal-img-link" target="_blank" rel="noreferrer"><img src={image7} alt="failed to load" className="gallery-img"/></a>
                    </div>
                    <div className="photo-card" id="card12">
                        <a href={image8} className="gal-img-link" target="_blank" rel="noreferrer"><img src={image8} alt="failed to load" className="gallery-img"/></a>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default AboutContents; 