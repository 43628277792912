import React from 'react'; 


import image5 from '../../images/IMG_6496.jpg';
import image6 from '../../images/IMG_6500.jpg';
import image10 from '../../images/IMG_8375.jpg';
import image11 from '../../images/IMG_8435.jpg';


const DonationContent : React.FC = () => {

    return (
        <div className="main-content-container">
            <h1 className="page-title">Donate to Areef Bhuyan for Pasadena ISD School Board</h1>
            <div className="donation-container">
                <div className="donation-info-container">
                    <div className="donation-info">
                        <p className="intro">
                            All funds donated will go towards advertising for the Elect Bhuyan Campaign. This will include the purchase of yard signs, social media advertisements, and various other advertising campaigns.
                        </p>
                        <p className="intro">
                            Funds should be donated through the website <a href="https://secure.actblue.com" target="_blank" rel="noreferrer" className="donate-link">actblue.com</a> using the link on this page. If you have any questions, please send me an email at <a href="mailto:electareef@gmail.com" className="email-link vote-link">ElectAreef@gmail.com</a>.
                        </p>
                    </div>
                </div>
                <div className="donate-container">
                    <div className="donate-link-container">
                        <a href="https://secure.actblue.com/donate/areef-bhuyan-for-school-board-1" target="_blank" rel="noreferrer" className="donation-link">Click HERE to donate!</a>
                    </div>
                </div>
            </div>
            <div className="gallery">
                <div className="photo-card" id="card3">
                    <a href={image11} className="gal-img-link" target="_blank" rel="noreferrer"><img src={image11} alt="failed to load" className="donate-img"/></a>
                </div>
                <div className="photo-card" id="card4">
                    <a href={image5} className="gal-img-link" target="_blank" rel="noreferrer"><img src={image5} alt="failed to load" className="donate-img"/></a>
                </div>
                <div className="photo-card" id="card5">
                    <a href={image6} className="gal-img-link" target="_blank" rel="noreferrer"><img src={image6} alt="failed to load" className="donate-img"/></a>
                </div>
                <div className="photo-card" id="card6">
                    <a href={image10} className="gal-img-link" target="_blank" rel="noreferrer"><img src={image10} alt="failed to load" className="donate-img"/></a>
                </div>
            </div>
        </div>
    )
}

export default DonationContent; 
