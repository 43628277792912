import React from 'react'; 


//Sub-Component Imports: 
import ContactInfo from './ContactInfo';
//import ContactForm from './ContactForm';


const ContactContent : React.FC = () => {
    
    return (
        <div className="main-content-container"> 
            <h1 className="page-title">Contact Me</h1>
            <h3 className="page-title">Reach out and let me know what I can do for you!</h3>
            <div className="contact-container">
               <ContactInfo />
               {/**<ContactForm />**/}
            </div>
      </div>
    )
}

export default ContactContent; 