import React from 'react';

//Sub-Component Imports: 
import MainCalendar from './MainCalendar';
//import ShareCalendar from './ShareCalendar';

const Calendar : React.FC = () => {

    return (
        <div className="main-content-container">
            <h1 className="page-title">Areef Bhuyan's Upcoming Events</h1>
            <div className="calendar-main-container">
              <MainCalendar />
              {/**<ShareCalendar />**/}
            </div>
        </div>
    )
}

export default Calendar; 