import React from 'react'; 


//Sub-Component Imports: 
import VotingList from './VotingList';
import CountdownTimer from './CountdownTimer';


const VotingContent : React.FC = () => {

    return (
        <div className="main-content-container">
            <h1 className="page-title">Vote for Areef Bhuyan for Pasadena ISD School Board</h1>
            <div className="vote-subheading">
                <div className="subsub">
                    <h3 className="page-title">Early Voting</h3>
                    <div>April 19-27, 2021</div>
                </div>
                <div className="subsub">
                    <h3 className="page-title">Election Day</h3>
                    <div>May 1, 2021</div>
                </div>
            </div>
            <div className="content-container">
                <p className="intro">If you have any questions about where to vote, when the election is taking place, how to get registered, or to find out if you are already registered, please keep reading. Below you'll find links that will take you where you need to go.</p>
                <div className="sub-container">
                    <VotingList />
                    <CountdownTimer />
                </div>
                <div className="sub-container">
                    <p className="intro">I would love to see you in Arpil or on May 1st, 2021 to support the future of Pasadena ISD's students, families, and the educators and staff who work to make Pasadena ISD the best it can be.</p>
                </div>
            </div>
        </div>
    )
}

export default VotingContent; 