import React from 'react'; 
import areefLogo from '../../images/areef_bhuyan_logo.png';


//React Centric Imports: 
import {Link} from 'react-router-dom';



const FullHeader : React.FC = () => {


    return (
        <div className="full-header-container">
            <div className="logo-container">
                <Link className="logo-link" to="/"> <img src={areefLogo} className="site-logo" alt="replacement" /></Link>
            </div>
            <div className="full-nav-container">
                <nav className="full-site-nav">
                  <div className="full-nav-link">
                    <Link to="/" className={"menu-link"}>Home</Link>
                  </div>
                  <div className="full-nav-link">
                    <Link to="/about" className={"menu-link"}>About</Link>
                  </div>
                  <div className="full-nav-link">
                    <Link to="/vote" className={"menu-link"}>Vote</Link>
                  </div>
                    {/**<div className="full-nav-link">
                    <Link to="/socialMedia" className={"menu-link"}>Social Media</Link>
                  </div>**/}
                  <div className="full-nav-link">
                    <Link to="/events" className={"menu-link"}>Events</Link>
                  </div>
                  <div className="full-nav-link">
                    <Link to="/donate" className={"menu-link"}>Donate</Link>
                  </div>
                  <div className="full-nav-link">
                    <Link to="/contact" className={"menu-link"}>Contact</Link>
                  </div>
                </nav>
            </div>
          </div>
    )
}


export default FullHeader; 
