import React from 'react'; 


const ContactInfo : React.FC = () => {
    
    return (
        <div className="alt-contact-container">
            <div className="alt-contact">I would love to hear from you! Not only are my kids going through Pasadena ISD schools, so are yours. Please let me know how you feel we can best work together to create a better future for our students, families, and teachers of Pasadena ISD. Just send me an email to the address below and I'll get back to you as soon as I can. Thank you so much for visiting and I look forward to hearing from you.</div>
            <div className="alt-contact contact-email">Email: <a href="mailto:electareef@gmail.com" className="email-link vote-link">ElectAreef@gmail.com</a></div>
            {/**<div className="alt-contact contact-or">...or just fill out the form and hit send!</div>**/}
        </div>
    )
}


export default ContactInfo; 
