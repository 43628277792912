import React from 'react'; 


const VotingList : React.FC = () => {

    return (
        <div className="vote-list-container">
            <div className="list-box">
                <ul className="vote-links">
                    <li>Don't know if you're registered? Click <a href="https://www.votetexas.gov/register/index.html" className="vote-link" target="_blank" rel="noreferrer">here</a>!</li>
                    <li>Need to register to vote? Click <a href="https://www.votetexas.gov/register/index.html" className="vote-link" target="_blank" rel="noreferrer">here</a>!</li>
                    <li>Not sure where to vote? Click <a href="https://teamrv-mvp.sos.texas.gov/MVP/mvp.do" className="vote-link" target="_blank" rel="noreferrer">here</a>!</li>
                    <li>Need info on how to vote? Click <a href="https://www.votetexas.gov/voting/how.html" className="vote-link" target="_blank" rel="noreferrer">here</a>!</li>
                    <li>Need to vote by mail? Click <a href="https://www.votetexas.gov/voting/when.html#helpful-hints-on-voting-early-by-mail" className="vote-link" target="_blank" rel="noreferrer">here</a>!</li>
                    <li>Have any other questions about voting? Visit <a href="https://www.votetexas.gov" className="vote-link" target="_blank" rel="noreferrer">VoteTexas.gov</a> for answers on general voting questions or <a href="https://www1.pasadenaisd.org/school_board/election_information/election_-_may_1__2021" className="vote-link" target="_blank" rel="noreferrer" >PasadenaISD.org</a> for specific questions about this election.</li>
                </ul>
            </div>
        </div>
    )
}


export default VotingList; 