
const aboutData = {

    topicNames : [
        "Budget", 
        "COVID-19", 
        "Policy", 
        "Accountability", 
        "Equity", 
        "Students & Families",
        "Teachers", 
    ], 

    topicDescriptions : [
        /*Budget*/"Communication is critical with budgets.  The community should know how their funds are supporting the students and have the chance to make their voices heard on this and any other issue.", 
        /*COVID-19*/"Students, teachers, and families should be provided accurate information on COVID-19 exposure so they can plan for their own response.  The board should release a current case count for each school with as much detail as they can provide in the interest of public safety.  This would include the school, what time infected individuals were onsite, and the approximate number of people to whom they were in close proximity.", 
        /*Policy*/"The school board should be clear about their reason for policy changes.  Communicating with the community on the rationale for policy decisions can often lead to greater understanding and cooperation, leading to more effective policies.", 
        /*Accountability*/"Accountability is a mainstay in school districts. Everyone is accountable to someone.  The board is accountable to the community, the superintendent is accountable to the board, and most importantly, teachers are accountable to the students.  In order for teachers to provide the best quality education to the students in their classes, we must all work together to hold one another accountable for maintaining the highest standards.", 
        /*Equity*/"The school district should provide resources to families, so the students have a greater chance of lifelong success.  Far too many students are limited later in life because of their situation as a child.  The school district should provide support to break those barriers and resources to address physical or emotional needs, allowing every student to reach their full potential.", 
        /*Students & Families*/"Families are the foundation of learning and meeting the basic needs of a family is critical to student success. Students should be given every opportunity to learn and grow in a learning environment that allows them to reach their dreams and avoid some of the hurdles in their lives.The school district can provide resources to families in the community to help them meet their needs. Whether it is food resources, family counseling, child care assistance, or simply providing a safe learning environment. When a child is worrying about their safety and security in life, they cannot learn so family assistance is education assistance.", 
        /*Teachers*/"Great teachers can change the lives of the people around them, but they are only human.  They are often underappreciated, stressed, and exhausted while sacrificing time with their own families to be there for their students.  Teacher burnout is real and needs to be addressed. Stopping teacher burnout starts with recognizing why and providing the support they need.  Teachers should have access to counseling:  a professional dedicated to meeting with teachers can help with strategies to be successful. Professionally, every teacher can be great but sometimes need a little help.  Teacher training should be focused on areas of improvement, not one-size-fits-all sessions.  Whether it’s training on creating engaging lessons or strategies for better classroom management, teachers know where they need help and should be able to get that help from the district."
    ]
}

export default aboutData; 

//Extension should be .ts rather than .tsx, no JSX is used here. 
