import React from 'react'; 


const CountdownTimer : React.FC = () => {

    const src = "https://free.timeanddate.com/countdown/i7p2fnrf/n4973/cf12/cm0/cu4/ct0/cs1/ca0/co1/cr0/ss0/cac0d37b0/cpc0d37b0/pct/tcfff/fs100/szw448/szh189/tatTime%20Until%20Election%20Day/tac0d37b0/tptTime%20To%20Go%20Vote!/tpc0d37b0/iso2021-05-01T07:00:00/bac0d37b0/pa20"; 

    return (
        <div className="countdown-container">
            <div className="countdown">
                <iframe src={src} allowTransparency={true} title="Voting" frameBorder="0" width="331" height="109"></iframe>
            </div>
        </div>
    )
}


export default CountdownTimer; 


//<iframe src="https://free.timeanddate.com/countdown/i7p2fnrf/n4973/cf12/cm0/cu4/ct0/cs1/ca0/co1/cr0/ss0/cac0d37b0/cpc0d37b0/pct/tcfff/fs100/szw448/szh189/tatTime%20Until%20Election%20Day/tac0d37b0/tptTime%20To%20Go%20Vote!/tpc0d37b0/iso2021-05-01T07:00:00/bac0d37b0/pa20" allowTransparency="true" frameborder="0" width="331" height="109"></iframe>
