import React from 'react';

//Style Imports: 
import './App.css';
import './abstyles.css';

//React Centric Imports: 
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom";

//Universal Component Imports: 
import Header from './Components/Navigation/Header';
import Footer from './Components/Navigation/Footer';

//Main Content Component Imports: 
import LandingWelcome from './Components/Navigation/LandingWelcome';
import SocialMediaUpdates from './Components/SocialMediaUpdates/SocialMediaUpdates';
import Calendar from './Components/Calendar/Calendar';
import Contact from './Components/Contact/ContactContent';
import VotingContent from './Components/Vote/VoteContent';
import DonationContent from './Components/Donate/DonationContent';
import AboutMe from './Components/AboutMe/AboutMe';



//App Component:

const App: React.FC = () => {
  return (

    <Router>
      <div className="App main-wrapper">
        <Header />

        {/* Main Content */}
        <main>
         <Switch>
          <Route exact path="/" component={LandingWelcome} />
          <Route exact path="/about" component={AboutMe} />
          <Route exact path="/socialMedia" component={SocialMediaUpdates} />
          <Route exact path="/events" component={Calendar} />
          <Route exact path="/contact" component={Contact} />
          <Route exact path="/vote" component={VotingContent} />
          <Route exact path="/donate" component={DonationContent} />
         
         </Switch>
        </main>
        

        <Footer />  
      </div>
    </Router>
  )
}



export default App;


/** Remaining Issues: 
 *  
 *  1. Research the merit of using a backend for API calls or keeping the Social Media API calls on the client side. 
 * 
 *  2. Research a method to have the contact form information sent to a specific email address. 
 * 
 *  3. Implement Social Media Integrations. 
 * 
 *  4. Implement researched method for contact form email sending. 
 *  
 */



/** Unfinished things: 
 * 
 *  1.  
 * 
 */



/** Remaining Issues to Fix: 
 * 
 * 1. Repositioning the social media icon in their containers. 
 * 
 *    A. Check to see if it is possible to pass down class names to the icon element. 
 * 
 * 
 * 2. Ensuring the entire footer stays on the bottom of the page. 
 * 
 *    A. Footer does not work well for the donation page. (Something different with this donation page CSS vs the other components)
 * 
 * 3. Figure out the issue with the First Name Input on the ContactContent Component (Not transitoning Properly and not able to see typed letters). 
 * 
 * 4. Figure out why the google calendar is so small compared to the rest of the page and find a way to style out the white space by making out the calendar bigger.
 * 
 * 
 * 5. FaceBook API: Everytime a new instance of the development server is activated, the FB Graph API does not produce the desired output for the page posts. 
 * 
 *    A. Investigate this and resolve the issue with access token renewal. 
 */