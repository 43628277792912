import React from 'react'; 
import areefLogo from '../../images/areef_bhuyan_logo.png';


//React Centric Imports: 
import {Link} from 'react-router-dom';

//Icon Imports: 
import {
    FaHome, 
    FaUser,
    /**FaHashtag,**/
    FaCalendar,
    FaHandHoldingUsd,
    FaCheckSquare,
    FaComments,
    FaBars
} from 'react-icons/fa'
  


const MobileHeader : React.FC = () => {


    return (
        <div className="mobile-header-container">
            <div className="logo-container">
              <img src={areefLogo} className="site-logo" alt="replacement" />
            </div>
            <div className="mobile-header-space"></div>
            <div className="mobile-menu-container">
              <div className="menu-container">
                <div className="menu-trigger">
                  <FaBars /> {/** Replace icon element with react component variant */}  {/* Done but double check me on this */}
                </div>
                <ul className="dropdown-menu">
                    <li className="menu-item">   <Link to="/" className={"mobile-menu-link"}> <FaHome /> Home</Link> </li>
                    <li className="menu-item">   <Link to="/about" className={"mobile-menu-link"}> <FaUser /> About </Link> </li>
                    <li className="menu-item">   <Link to="/vote" className={"mobile-menu-link"}>  <FaCheckSquare />  Vote </Link> </li>
                    {/**<li className="menu-item">   <Link to="/socialMedia" className={"mobile-menu-link"}> <FaHashtag /> Social Media </Link> <FaHashtag /> </li>**/}
                    <li className="menu-item">   <Link to="/events" className={"mobile-menu-link"}> <FaCalendar /> Events </Link> </li>
                    <li className="menu-item">   <Link to="/donate" className={"mobile-menu-link"}> <FaHandHoldingUsd /> Donate </Link> </li>
                    <li className="menu-item">   <Link to="/contact" className={"mobile-menu-link"}>  <FaComments />  Contact </Link> </li>
                </ul>
              </div>
            </div>
        </div>
    )
}


export default MobileHeader; 
