import React from 'react';
import image from "../../images/IMG_2020.jpg"


const LandingWelcome: React.FC = () => {
    return (  
        <div className="main-content-container">
            <h1 className="page-title">Areef Bhuyan for Pasadena ISD School Board</h1>
            <div className="home-page-intro page-content-container">
                <img src={image} alt="failed to load" className="intro-photo"/>
                <div className="intro-box">
                    <p className="intro">My highest priority as a member of Pasadena Independent School DIstrict's board is to support our school system’s long-term success so that every PISD student can get the very best education available. This work is not possible without our incredible teachers, principals, school bus drivers, staff, and superintendent, nor the extraordinary support of current and former Board members as well as our parents, families, and community.</p>
                    <p>As a former public school teacher, husband to a current teacher, and father of two sons who are attending or will attend Pasadena schools, there are three main reasons why I’ve chosen to run for the school board:
                        <ul className="intro-list">
                            <li>Teachers and students are best served when those in our community are engaged and heard. Additionally, I believe that school districts should drive their decision-making through how students, teachers, and families are impacted. That’s why throughout the campaign process – and, should I be elected, throughout my tenure on the Board – I‘ll host online events and be accessible to residents and my neighbors throughout Pasadena.</li>
                            <li>Communication is a responsibility, not an afterthought. From budget planning to policy changes to accurate COVID-19 information, open and thoughtful communication is critical as it often leads to greater collaboration and cooperation, as well as encourages community support. After all, school boards exist to provide support and oversight from the community to the school district. </li>
                            <li>In these challenging times and always, teachers deserve more balanced and supportive resources. Additionally, students need integrated education methodologies to become resilient, achieving members of society. This holistic approach not only helps children develop academically and intellectually but prepares students for the future.</li>
                        </ul>
                    </p>
                    <p>I invite residents to follow me on social media to learn more about my campaign and upcoming events. You may also browse my website for more information. Can I count on your vote on May 1st?</p>
                </div>
            </div>
        </div>
    )
}

export default LandingWelcome; 