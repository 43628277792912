import React, {useState} from 'react'; 


//Sub-Component Imports: 
import AboutContent from './AboutContent';
import TopicContents from './TopicContents';

//Icon Imports: 
import {FaUserTie, FaTasks} from 'react-icons/fa';


const AboutMe : React.FC = () => {


    //State management for tab selection: 
    const [tabNumber, setTab] = useState(true); 
    let selectedTab = <AboutContent />; 
    if (!tabNumber) {
      selectedTab = <TopicContents />;   
    }


    return (
        <div className="main-content-container">
            <h1 className="page-title">About Areef Bhuyan</h1>
            <div className="tabs-container">
                <button id="about-tab" onClick={() => setTab(true)} className="tab about-tab"><FaUserTie /><span className="tab-label">About Me</span></button>
                <button id="topic-tab" onClick={() => setTab(false)} className="tab about-tab"><FaTasks /><span className="tab-label">Topics and Positions</span></button>
            </div>
            <div className="tabs-content-container">
               {selectedTab}
            </div>
      </div>
    )
}


export default AboutMe; 
