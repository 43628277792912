import React, {useState, useEffect} from 'react'; 
const axios = require("axios");


//Helper Links: 
const fetchURL = "http://localhost:8080"


//Prop Interfaces: 
interface twitterDivProps {
    id : number; 
    text: string; 
    createdAt : string; 
    username : string; 
    handle : string; 
}

//Creating an array
interface twitterBackUpText extends Array<twitterDivProps>{};

//Setting up backup defaults for Areef's Twitter
const twitterBackUpTextObj: twitterBackUpText = [
    {
        id: 1,
        text: "Far too many students are limited due to mental and emotional strife. School districts should break down barriers and provide resources to families to address physical or emotional needs, ensuring students have a greater chance of lifelong success and reach their full potential.",
        createdAt: "",
        username: "Areef Bhuyan for Pasadena ISD School Board",
        handle: "ElectAreef",
    },
    {
        id: 2,
        text: "Part of my campaign platform centers on COVID-19. That the board provides accurate information on exposure rates in our schools so parents and teachers can plan for their own response. Accountability, transparency, and communication are central to an effective school board.",
        createdAt: "",
        username: "Areef Bhuyan for Pasadena ISD School Board",
        handle: "ElectAreef",
    },
    {
        id: 3,
        text: "It's official! I filed to run for @PasadenaISD_TX School Board. I'm doing this for the future of my two boys and for my wife, Nikki – a teacher in PISD. Teachers give so much of their time and energy to support our children. It's time the school board supports them in return.",
        createdAt: "",
        username: "Areef Bhuyan for Pasadena ISD School Board",
        handle: "ElectAreef",
    }
]


//Helper Components and Functions: 
const TwitterDiv : React.FC<twitterDivProps> = (props) => {
    return (
        <div className="post-container">
            <div id={`twitter-post-${props.id + 1}`} className="post twitter-post">
                <h2>{props.username}</h2>
                <h3>@{props.handle}</h3>
                <p>{props.text}</p>
                <p>{props.createdAt}</p>
            </div>
        </div>
    )
}

const prepareContainers = (data : twitterDivProps[]) => {
    let twitterDivs = data.map((twitterObject, i) => {
        return <TwitterDiv id={i}  
                           username={twitterObject.username}
                           handle={twitterObject.handle}
                           text={twitterObject.text}
                           createdAt={twitterObject.createdAt}
                            />
    })
    return twitterDivs;
}


const TwitterContent: React.FC = () => {
    //Fetch Twitter Objects:  
    const [twitterData, setData] = useState<twitterDivProps[]>([]);
    const getTwitterData = async () => {
        const response = await axios.get(fetchURL + "/twitter"); 
        try {
            // console.log(response);
            const data : twitterDivProps[] = response.data;
            return data; 
        } catch (error) {
            console.log(error);
        }
       
    }

     //Convert twitter objects to containers: 
     useEffect (() => {
        getTwitterData().then((data) => {
           if (data)
                setData(data);
           else 
                setData([]); 
        }).catch(() => {
            setData(twitterBackUpTextObj)
        });
    }, []); 

    const twitterContainers = prepareContainers(twitterData); 

    return (
        <div id="twitter-contents" className="tab-content">
            <div className="postbox"> 
              {twitterContainers}
            </div>
            <div className="see-more">Click <a href="https://twitter.com/BhuyanElect" className="soc-med-link" rel="noreferrer" target="_blank">here</a> to see more!</div>
        </div>
    )
}

export default TwitterContent; 