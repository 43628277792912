import React from 'react';

//Sub-Component Imports: 
import MobileHeader from './MobileHeader';
import FullHeader from './FullHeader';



const Header: React.FC = () => {

    return (
        <header>
          <MobileHeader />
          <FullHeader /> 
        </header>
    )
}

export default Header; 