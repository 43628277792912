const path = require('path');
require('dotenv').config({path: path.resolve(__dirname, '../../.env')});

const { FB_APP_ID } = process.env;

declare global {
    interface Window {
      fbAsyncInit: () => void;
      FB : any; 
    }
}


export function initFacebookSdk() {
    return new Promise(resolve => {
        window.fbAsyncInit = function () {
            window.FB.init({
                appId: FB_APP_ID,
                cookie: true,
                xfbml: true,
                version: 'v9.0'
            });

        };

        // load facebook sdk script
        (function (d, s, id) {
            var js : any, fjs = d.getElementsByTagName(s)[0];
            if (d.getElementById(id)) { return; }
            js = d.createElement(s); js.id = id;
            js.src = "https://connect.facebook.net/en_US/sdk.js";
            fjs.parentNode!.insertBefore(js, fjs);
        }(document, 'script', 'facebook-jssdk'));    
    });
}