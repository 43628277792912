import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import {initFacebookSdk} from './FB_SDK_Files/FB_SDK';
import App from './App';
import reportWebVitals from './reportWebVitals';

//Initialize FB SDK for use: 
initFacebookSdk();

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
