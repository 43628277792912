import React, {useState, useEffect} from 'react'; 
const axios = require("axios");


//Helper Links: 
const fetchURL = "http://localhost:8080"


//Prop Interfaces: 
interface imageDivProps {
    id : number; 
    url : string; 
}

// Instagram backup image url
const instaBackUpImagesObj: string[] = [
    "https://scontent-dfw5-2.xx.fbcdn.net/v/t1.0-9/60781785_10101498144473971_6583420914640093184_n.jpg?_nc_cat=106&ccb=3&_nc_sid=8bfeb9&_nc_ohc=HJ44OyN0744AX_J-efi&_nc_ht=scontent-dfw5-2.xx&oh=974817ee3e856c1114c32d0be772657c&oe=605559C6",
    "https://scontent-dfw5-2.xx.fbcdn.net/v/t1.0-9/147003491_180140817235126_8181282190753455120_n.jpg?_nc_cat=100&ccb=3&_nc_sid=730e14&_nc_ohc=X7aVexOlInoAX8GJTrT&_nc_ht=scontent-dfw5-2.xx&oh=e4367b48b7ac6e4fd2bc6c0df614f7c4&oe=60524C1D",

]


//Helper Components and Functions: 
const ImageDiv : React.FC<imageDivProps> = (props) => {
    return (
        <div className="post-container">
            <div  id={`insta-post-${props.id}`} className="post insta-post">
                <img src={props.url} alt="Instagram Card" className="intro-photo bio-photo" />
            </div>
        </div>
    )
} 

const prepareContainers = (data : string[]) => {
    let imageDivs = data.map((url, i) => {
        return <ImageDiv url={url} id={i} />
    });

    return imageDivs;
}


const InstaContent: React.FC = () => {
    //Fetch photo URLs via backend: 
    const [instaData, setData] = useState<string[]>([]);
    const getInstaData = async () => {
        const response = await axios.get(fetchURL + "/insta");
        // console.log(response.data);
        const data : string[] = response.data; 
        return data;
    }
    
    //Convert photo URLs to container components: 
    useEffect (() => {
        getInstaData().then((data) => {
            if (data) {
                setData(data);
            } else {
                setData([]);
            }
        }).catch(()=>{
            setData(instaBackUpImagesObj);
        });
    }, []); 
    
    const imagesContainers = prepareContainers(instaData);


   
    return (
        <div id="insta-contents" className="tab-content">
            <div className="postbox"> 
                {imagesContainers}
            </div>
            <div className="link see-more">Click <a href="https://www.instagram.com/bhuyanelect/" className="soc-med-link" rel="noreferrer" target="_blank">here</a> to see more!</div>
        </div>
    )
}

export default InstaContent;


