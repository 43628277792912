import React from 'react'; 

//Data imports: 
import aboutData from './Data_AboutMeTopics/data';

//Interfaces: 
interface TopicData {
    topicNames : string[];
    topicDescriptions : string[];
}

interface TopicRowProps {
    topicName : string; 
    topicDescription : string; 
}


//Sub-Components: 
const TopicRow : React.FC<TopicRowProps> = (props) => {
    return (
        <div className="topic-row">
            <h2 className="page-title topic-heading">{props.topicName}<i className="fa fa-chevron-right topic-arrow"></i>{/* This icon can get taken out. It doesn't need to be in there. */}</h2>
            <div className="position-container">
                <p className="intro">{props.topicDescription}</p>
            </div>
        </div>
    )
}

//Helper Functions: 
const prepareTopics = (topicData : TopicData) => {
    return topicData.topicNames.map((topicName, i) => {
        return  <TopicRow 
                    topicName={topicName} 
                    topicDescription={topicData.topicDescriptions[i]} 
                /> 
    })
}


//Main Component: 
const TopicContents : React.FC = () => {

    const topicRows = prepareTopics(aboutData); 

    return (
            <div id="topic-contents" className="tab-content">
                <div className="topic-position-container page-content-container">
                    <div className="topics-container">
                       {topicRows}
                    </div>
                </div>
            </div>
    )
}

export default TopicContents; 


/**
 * (({name, description}) => <Component topicName={name} topicDescription={dscription} />) 
 * 
 * 
 * 
 */