import React from 'react';

//Icon imports: 
import {FaFacebookF, FaInstagram, FaTwitter} from 'react-icons/fa'; 

const Footer: React.FC = () => {
    
    return (
        <footer>
            <div className="foot-container">
                <div className="social-media-container">
                    <div className="social-media-icons">
                    <a href="https://www.facebook.com/Areef-Bhuyan-for-Pasadena-School-Board-110565990859276" className="icon facebook-icon" target="_blank" rel="noreferrer">  
                        <FaFacebookF />  
                    </a>
                    <a href="https://www.instagram.com/electareef/" className="icon instagram-icon"  target="_blank" rel="noreferrer" >
                        <FaInstagram /> 
                    </a>
                    <a href="https://twitter.com/ElectAreef" className="icon twitter-icon" target="_blank" rel="noreferrer"> 
                        <FaTwitter /> 
                    </a>
                    </div>
                </div>
                <div className="copyright-container">
                    <div className="copyright">&copy; Copyright 2020 Areef Bhuyan, All Rights Reserved</div>
                    <div className="copyright">Website by Nathan Reeves, David Barth, and Munir Hassan</div>
                    <div className="copyright">Not registered to vote? Click <a href="https://www.votetexas.gov/register/index.html" className="register" target="_blank" rel="noreferrer">here</a>!</div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;